import { FC, useRef } from "react"
import useIntersectionObserver from "../../../hooks/useIntersectionObserver"
import { ProductsContainer } from "./ProductsContainer"
import { useAppSelector } from "@/hooks/redux"

export const PersonalRecommendations: FC = () => {
  const isAuth = useAppSelector(({ profile }) => profile.isAuth)

  const containerRef = useRef(null)
  const intersection = useIntersectionObserver(containerRef, {
    freezeOnceVisible: true,
  })
  const inView = intersection?.isIntersecting ?? false

  if (!isAuth) {
    return null
  }

  return <div ref={containerRef}>{inView && <ProductsContainer />}</div>
}
