import { FC } from "react"
import { ProductsGrid } from "../../Products/Grid"
import { StyledTileProducts } from "../../Products/StyledProductsGrid"
import { useRecommendations } from "@/hooks/recommendations"
import { useAppSelector } from "@/hooks/redux"
import { Container } from "@/styles/utils/StyledGrid"
import { SectionProducts, SectionTitle } from "@/styles/utils/Utils"
import { VIEW_PRODUCTS_GRID } from "@/utils/constants"

const ProductsContainer: FC = () => {
  const isAuth = useAppSelector(({ profile }) => profile.isAuth)
  const { products } = useRecommendations({
    limit: 10,
    isAuth,
  })

  if (!products || !products.length) {
    return null
  }

  return (
    <SectionProducts>
      <Container>
        <SectionTitle>Персональные рекомендации</SectionTitle>
        <ProductsGrid
          as={StyledTileProducts}
          products={products}
          view={VIEW_PRODUCTS_GRID}
        />
      </Container>
    </SectionProducts>
  )
}

export { ProductsContainer }
